@import "xterm/css/xterm.css";
@import "purecss";

body {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1em;

    display: flex;
    flex-direction: column;
}

#app {
    flex-grow: 1;
    height: 50%;
    width: 100%;
    padding-bottom: 2em;

    display: flex;
    flex-direction: column;
}

.connect-view {
    font-size: 120%;
}

.message-box {
    margin-top: 40px;
    margin-bottom: 20px;
}

.app-view {
    flex-grow: 1;
    height: 50%;
    width: 100%;

    display: flex;
    flex-direction: column;
}

.tab-row {
    display: flex;
}

.right-of-tabs {
    margin-left: auto;
}

.zoom {
    display: flex;
}

.zoom input {
    margin-left: 1ex;
}

.tab-view {
    flex-grow: 1;
    height: 50%;
    margin-top: 1em;

    display: flex;
    flex-direction: column;
}

.tab-view-selected {
    flex-grow: 1;
    height: 50%;
    margin-top: 1em;

    display: flex;
    flex-direction: column;
}

.term-view {
    flex-grow: 1;
    height: 50%;
    overflow: hidden; /* terminal will resize */
}

.plot-view {
    flex-grow: 1;
    height: 80%;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
}

.plot-main-buttons {
    margin-right: 1em;
}

.swatch-button {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.swatch {
    display: inline-block;
    vertical-align: middle;

    width: 8px;
    margin-left: 8px;
    margin-right: 8px;

    height: 8px;
}

.swatch-lit {
    width: 24px;
    height: 24px;
    margin-left: 0;
    margin-right: 0;
}

.swatch-bottom {
    width: 24px;
    margin-left: 0;
    margin-right: 0;
}

.swatch-button button {
    margin-left: 6px;
    font-size: 80%;
}

.button-pressed {
    box-shadow: inset 1px 2px 5px #777;
    transform: translateY(1px);
    background: #e5e5e5;
}

.plot-main {
    display: flex;
    flex-direction: column;
}

.slider  {
    display: flex;
    margin-left: 40px;
    height: 1ex;
    border: 1px solid grey;
}

.slider .thumb {
    background-color: lightgrey;
}

.plot-main-graph {
    flex-grow: 1;
}

.bottom-plot-label {
    margin-right: 1em;
    height: 100px;

    display: flex;
    align-items: center;
}

.bottom-plot-label div {
    flex-grow: 1;
    text-align: right;
}

#file-prefix-input {
    text-align: right;
}

.form-item-text {
    vertical-align: middle;
}
